

module directives {
    export module documentManagement {
        interface IDocumentRepositoryScope extends ng.IScope {
            documentRepository: interfaces.documentManagement.IDocumentRepository,
            gvwDocumentFiles: uiGrid.IGridOptions,
            gridApi: uiGrid.IGridApi,
            openRepositoryFile(docRep: interfaces.documentManagement.IDocumentRepository, rdocFile: interfaces.documentManagement.IDocumentRepositoryFileView): void,
            downloadFile(key: string, IsViewable?: boolean): void,
            refresh(any: any): ng.IPromise<any>,
            removeClick(): void,
            hasDocumentPrint: boolean,
            printDocuments(): void,
            selectedDoc: uiGrid.IGridRow,
            showAllChanged():void,
            showAll:boolean
            downloadFiles();      
            emailFiles();      
            canDownloadEmail:boolean;
            fileLeysEmail: interfaces.applicationcore.FileKeys;

            //HTML5 Drag and Drop Methods
            files: any; 
            filesToUpload: any;

            dragStart($event: any);
            dragDrop($event: any);
            dragEnter($event:any);
            dragOver($event:any);
        }

        export class documentRepositoryDirective implements ng.IDirective {
            restrict = 'E';
            templateUrl = 'templates/modules/documentManagement/documentRepositoryDirectiveView.html';
            scope = {
                documentRepository: "=",
                ngDisabled: "=",
                refresh: '&',
                printDocuments: '&?',
                hasDocumentPrint: "=?",
                showAll: "=",

                filesToUpload: "=?"
            }

            constructor(public $uibModal: ng.ui.bootstrap.IModalService, public repositoryService: interfaces.applicationcore.IRepositoryService,
                public generalService: interfaces.applicationcore.IGeneralService, public documentRepositoryFileService: interfaces.documentManagement.IDocumentRepositoryFileService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService, private menuService: interfaces.applicationcore.IMenuService, private $rootScope: interfaces.applicationcore.IRootScope,
                public userService: interfaces.applicationcore.IUserAccountService) {

            }

            link = ($scope: IDocumentRepositoryScope, $element: ng.IAugmentedJQuery) => {



                
                $scope.$watch("documentRepository", (newValue: interfaces.documentManagement.IDocumentRepository, oldValue: interfaces.documentManagement.IDocumentRepository) => {
                    if (newValue){
                        $scope.documentRepository =newValue;
                        $scope.gvwDocumentFiles.data = $scope.documentRepository.DocumentRepositoryFiles;
                        if ( $scope.gridApi){
                            $scope.gridApi.grid.refresh();
                        }
                    }
                }, true);

               
                     this.menuService.getGTSConnectMenuItem(576).get((result: interfaces.applicationcore.IMenu) => {
                        if (result && result.name) {
                            $scope.canDownloadEmail = true;
                        }
                        else{
                            $scope.canDownloadEmail= false;
                        }
                    });
            


                if (angular.isDefined($scope.printDocuments)) {
                    $scope.hasDocumentPrint = true;
                }

                $scope.gvwDocumentFiles = {
                    data: [],
                    enableFiltering: false,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: true,                    
                    enableColumnResizing: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: true,
                    enableHorizontalScrollbar: 2,
                    onRegisterApi: (gridApi) => {
                        $scope.gridApi = gridApi;

                        $scope.gridApi.selection.on.rowSelectionChanged($scope, (selected) => {
                            $scope.selectedDoc = selected;
                        });
                        
                        $scope.gvwDocumentFiles.data = $scope.documentRepository.DocumentRepositoryFiles;
                    },
                    columnDefs: [{
                        name: "EDIT",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-disabled="grid.appScope.ngDisabled"  ng-click="grid.appScope.openRepositoryFile(grid.appScope.documentRepository, row.entity)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 35
                    },{
                        name: "VIEW",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-disabled="!row.entity.IsViewable" ng-click="grid.appScope.downloadFile(row.entity.Key, row.entity.IsViewable)" class="btn btn-default btn-sm">
                                    <span class="fa fa-eye"></span>
                                </button>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 35
                        }, {
                            name: "REFNUMBER",
                            displayName: "Reference Number",
                            field: "ReferenceNumber",
                            width: 175
                    }, {
                        name: "CREATEDATE",
                        displayName: "Date Created",
                        field: "RepositoryFileCreateStampDate",
                        width: 125,
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD HH:mm"',
                    }, {
                        name: "REFDATE",
                        displayName: "Reference Date",
                        field: "RepositoryFileReferenceDate",
                        width: 125,
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    }, {
                        name: "DESCRIPTION",
                        displayName: "Description",
                        field: "RepositoryDescription",
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 200
                    }, {
                        name: "DOCTYPE",
                        displayName: "Document Type",
                        field: "DocumentTypeDescription",
                        width: 150
                    }, {
                        name: "TRANSTYPE",
                        displayName: "Transaction Type",
                        field: "TypeDescription",
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 150
                    }, {
                        name: "DOCREFERENCE",
                        displayName: "Document Reference Number",
                        field: "RepositoryFileReferenceNumber",
                        width: 225
                    }, {
                        name: "FILENAME",
                        displayName: "File Name",
                        field: "FileName",
                        width: 125
                    }, {
                        name: "REQUIRED",
                        displayName: "Required",
                        enableFiltering: false,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.Required">`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 75
                    }]
                };


                $scope.emailFiles = () =>{
                    
                    var selectedRows: Array<interfaces.documentManagement.IDocumentRepositoryFileView> = $scope.gridApi.selection.getSelectedRows();
                    
                    if (!selectedRows || selectedRows.length <= 0) {
                        this.generalService.displayMessage("At least one document should be selected to email", Enum.EnumMessageType.Warning);
                        return;
                    }

                    var keyGuids =  selectedRows.map((value)=>{
                        return  <interfaces.applicationcore.FileKey>{
                            fileName: value.FileName,
                            fileType: value.FileType,
                            keyGuid: value.Key
                         }
                    });    

                    var fileKeys = <interfaces.applicationcore.FileKeys>{
                        KeyGuids:keyGuids
                    }

                    $scope.fileLeysEmail = fileKeys;

                    return this.$uibModal.open({
                        animation: true,
                        size: "lg",
                        template: `<div class="modal-header">
                        <h3 class="modal-title" id="modal-title">Email Documents</h3>
                        </div>
                            <div class="modal-body" id="modal-body">
                            <gts-message-display messages="Messages"></gts-message-display>
                                <div bs-loading-overlay bs-loading-overlay-reference-id="email.doc">
                                    <form name="inputForm">

                                        <div class="row">
                                            <div class="vertical-align">
                                                <div class="col-md-2">
                                                    <req></req>
                                                    <label>From</label>
                                                </div>
                                                <div class="col-md-10">
                                                    {{emailDocumentCtrl.emailFrom}}
                                                </div>
                                            </div>   
                                        </div>    
                                        <div class="row"> 
                                            <div class="vertical-align">
                                                <div class="col-md-2">
                                                    <req></req>
                                                    <label>To</label>
                                                </div>
                                                <div class="col-md-10">
                                                    <p class="input-group-sm">
                                                    <input class="form-control" type="text" ng-model="emailDocumentCtrl.emailTo"
                                                        aria-label="Email To">
                                                    </p>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="row"> 
                                            <div class="vertical-align">
                                                <div class="col-md-2">
                                                    <req></req>
                                                    <label>Subject</label>
                                                </div>
                                                <div class="col-md-10">
                                                    <p class="input-group-sm">
                                                    <input class="form-control" type="text" ng-model="emailDocumentCtrl.emailSubject"
                                                        aria-label="Email To">
                                                    </p>
                                                </div>
                                            </div> 
                                        </div>                                
                                        <div class="row vertical-align">
                                            <div class="col-md-2">
                                                <req></req>
                                                <label>Body</label>
                                            </div>
                                            <div class="col-md-10">
                                                <p class="input-group-sm has-feedback" ng-class="{'has-error': docNotes.$invalid}">
                                                    <textarea id="docNotes" class="form-control" ng-model="emailDocumentCtrl.emailBody" rows="25"></textarea>
                                                </p>
                                            </div>                                               
                                        </div>                                                                                                          
                                    
                                    </form>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button class="btn btn-primary" type="button"  ng-disabled="!emailDocumentCtrl.emailFrom || !emailDocumentCtrl.emailTo || !emailDocumentCtrl.emailSubject" ng-click="emailDocumentCtrl.ok()">Ok</button>
                            <button class="btn btn-info" type="button" ng-click="emailDocumentCtrl.cancel()">Close</button>
                            </div>`,
                        controller: class emailDocumentCtrl {
                            
                            emailFrom:string = this.$rootScope.userEmail;
                            emailTo:string;
                            emailSubject:string;
                            emailBody:string;

                           


                            constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, 
                                private repositoryService: interfaces.applicationcore.IRepositoryService,public generalService: interfaces.applicationcore.IGeneralService, 
                                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService, private $rootScope: interfaces.applicationcore.IRootScope,private userAccountService: interfaces.applicationcore.IUserAccountService,
                               private fileKeys:interfaces.applicationcore.FileKeys) {
                                
                                if (this.emailFrom === "" || !this.emailFrom){

                                    this.userAccountService.loadUserProfile().get((result: interfaces.applicationcore.IUser) => {
                                        this.emailFrom = result.Email;
                                    }, (errorResult) => {
                                            this.generalService.displayMessageHandler(errorResult.data);
                                        });

                                }
                                
                            }
    
                            cancel() {
                                this.$uibModalInstance.dismiss();
                            }
    
                            ok() {

                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'email.doc'
                                }, () => {
                                    return this.emailDocuments();
                                });
                            }
    
                            emailDocuments(){
                                    var emailHelper = <interfaces.applicationcore.EmailHelper>{
                                                             From:this.emailFrom,
                                                             To: this.emailTo,
                                                             Subject: this.emailSubject,
                                                             Body:this.emailBody
                                                        }
                              
                                    var emailFileContent = <interfaces.applicationcore.EmailFileContent>{
                                        fileKeys: this.fileKeys,
                                        emailDetail: emailHelper
                                    }

                                    return this.repositoryService.EmailFiles().save( emailFileContent , (data: interfaces.applicationcore.IMessageHandler) =>{
                                        this.generalService.displayMessageHandler(data);
                                        if (!data.HasErrorMessage){
                                            this.$uibModalInstance.close();
                                        }
                                    });
                                
                            }
                        },
                        controllerAs: "emailDocumentCtrl",
                        resolve: {
                            fileKeys : $scope.fileLeysEmail
                        }
                    }).result;


                }

                $scope.downloadFiles = () => {

                    var selectedRows: Array<interfaces.documentManagement.IDocumentRepositoryFileView> = $scope.gridApi.selection.getSelectedRows();
                    
                    if (!selectedRows || selectedRows.length <= 0) {
                        this.generalService.displayMessage("At least one document should be selected to Download", Enum.EnumMessageType.Warning);
                        return;
                    }

                   var keyGuids =  selectedRows.map((value)=>{
                        return  <interfaces.applicationcore.FileKey>{
                            fileName: value.FileName,
                            fileType: value.FileType,
                            keyGuid: value.Key
                         }
                    });    

                    var fileKeys = <interfaces.applicationcore.FileKeys>{
                        KeyGuids:keyGuids
                    }
                    
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'document.rep.update'
                    }, () => {
                        return this.repositoryService.downloadRepositoryFiles(fileKeys);
                    });

                }

                $scope.openRepositoryFile = (docRep: interfaces.documentManagement.IDocumentRepository, rdocFile: interfaces.documentManagement.IDocumentRepositoryFileView) => {

                    this.$uibModal.open({
                        animation: true,
                        templateUrl: 'templates/modules/documentManagement/documentRepositoryFileEditView.html',
                        controller: 'documentRepositoryFileEditCtrl',
                        controllerAs: 'docRepCtrl',
                        size: "md",
                        resolve: {
                            items: function () {
                                return {
                                    DocRep: docRep,
                                    DocFile: rdocFile
                                };
                            },
                            IsReadOnly: () => {

                                if (rdocFile)
                                {
                                    return rdocFile.IsReadOnly;
                                }
                                else
                                {
                                    return false;
                                }

                            }
                        }
                    }).result.then((result: boolean) => {
                        if (result) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'document.rep.update'
                            }, () => {
                                return $scope.refresh({ showAll: $scope.showAll } );
                            });
                        }
                    });
                }

                $scope.downloadFile = (key: string, IsViewable?: boolean) => {
                    this.repositoryService.downloadRepositoryFile(key, IsViewable);
                };

                $scope.showAllChanged = () =>{

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'document.rep.update'
                    }, () => {
                        return $scope.refresh({ showAll: $scope.showAll });
                    });
                }

                $scope.removeClick = () => {
                    var selectedRows = <Array<interfaces.documentManagement.IDocumentRepositoryFileView>>$scope.gridApi.selection.getSelectedRows();

                    if (selectedRows && selectedRows.length <= 0) {
                        return;
                    }

                    if (selectedRows.find(x=>x.IsDeletable===false)){
                        this.generalService.displayMessage("You are unable to delete selected document(s)!", Enum.EnumMessageType.Error);
                        return;
                    }

                    if (!selectedRows[0].IsDeletable)
                    {
                        this.generalService.displayMessage("You are unable to delete this document!", Enum.EnumMessageType.Error);
                        return;
                    }

                    var keyGuids =  selectedRows.map((value)=>{
                        return  <interfaces.applicationcore.FileKey>{
                            fileName: value.FileName,
                            fileType: value.FileType,
                            keyGuid: value.Key
                         }
                    });    

                    var fileKeys = <interfaces.applicationcore.FileKeys>{
                        KeyGuids:keyGuids
                    }

                    this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove "+selectedRows.length  +" document(s)?").then((result: boolean) => {
                        if (result) {
                            this.documentRepositoryFileService.deleteMultipleFiles().save(fileKeys, (response: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(response);

                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'document.rep.update'
                                }, () => {
                                    return $scope.refresh({ showAll: $scope.showAll });
                                    });
                                
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }
                    });
                }


                /*  Drag and Drop HTML5 methods */

                $scope.files = [];

                $scope.dragStart = ($event: any) =>{
                    if ( event != null){
                        $event.preventDefault();
                    }

                }

                $scope.dragDrop = async ($event: any) => {
                    if ($event != null) {
                        $event.preventDefault();
                    }
                
                    let fileObjectsArray = [];
                
                    // Helper function to read a file with FileReader
                    const readFile = (file: File) => {
                        return new Promise<any>((resolve, reject) => {
                            const reader = new FileReader();
                
                            reader.onload = (e) => {
                                resolve(e.target.result);
                            };
                
                            reader.onerror = (e) => {
                                reject('Error reading file');
                            };
                
                            reader.readAsDataURL(file);
                        });
                    };
                
                    // Loop through files and process them
                    for (let i = 0; i < $event.dataTransfer.files.length; i++) {
                        const file = $event.dataTransfer.files[i];
                        
                        try {
                            const newFilePreview = await readFile(file); // Wait for the file to be read
                            
                            // Create the file object after the file is read
                            const newFileName = file.name;
                            const newFileSize = file.size;
                            const newFileContentBase64 = newFilePreview.split(',')[1];
                
                            const fileObject = {
                                file: file,
                                name: newFileName,
                                size: newFileSize,
                                preview: newFilePreview,
                                base64: newFileContentBase64
                            };
                
                            fileObjectsArray.push(fileObject);
                        } catch (error) {
                            console.error('Error processing file:', error);
                        }
                    }
                
                    // Assign the files to upload to the scope              
                
                
                    if (fileObjectsArray.length !== 0) {
                        const repositoryFile = <interfaces.documentManagement.IDocumentRepositoryFileView> {
                            RepositoryFileId: undefined,
                            DocumentRepositoryId: 0,
                            FileId: undefined,
                            Key: undefined,
                            FileType: fileObjectsArray[0].file.type,
                            FileName: fileObjectsArray[0].name,
                            Filesize: fileObjectsArray[0].size,
                            FileBase64: fileObjectsArray[0].base64,
                            IsDragdrop: true
                        };
                                    
                        $scope.openRepositoryFile($scope.documentRepository, repositoryFile);
                    }
                };

                $scope.dragEnter = ($event:any) => {
                    if ( event != null){
                        $event.preventDefault();
                    }

                    $event.dataTransfer.affectAllowed = 'copy';
                }

                $scope.dragOver = ($event:any) => {
                    if ( event != null){
                        $event.preventDefault();
                    }

                    $event.dataTransfer.affectAllowed = 'copy';
                }                

                /* End Drag and Drop HTML5 methods */
            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($uibModal, repositoryService, generalService, documentRepositoryFileService, bsLoadingOverlayService,menuService,$rootScope,userAccountService) =>
                    new documentRepositoryDirective($uibModal, repositoryService, generalService, documentRepositoryFileService, bsLoadingOverlayService,menuService,$rootScope,userAccountService);
                directive.$inject = ['$uibModal', 'repositoryService', 'generalService', 'documentRepositoryFileService', 'bsLoadingOverlayService','menuService',"$rootScope",'userAccountService'];

                return directive;
            }
        }
        angular.module("app").directive("gtsDocumentRepository", documentRepositoryDirective.factory());
    }
}